import Login from "../pages/Auth/Login"
import Register from "../pages/Auth/Register"
import ForgottenPassword from "../pages/Auth/ForgottenPassword"
import RecoverPassword from "../pages/Auth/RecoverPassword"
import Maintenance from '../pages/Auth/Maintenance'
import Logout from "../pages/Auth/Logout"
import LoginQrView from "../pages/Auth/LoginQr/view"
import ViewLogin from "../pages/Auth/LoginQr/viewLogin"
import LoginQr from "../pages/Auth/LoginQr"
import Homepage from "../pages/Auth/Homepage"

const baseTitle = "Offing - "

export const RouteAuth = {
    HOMEPAGE: {
        path: "/",
        page: Homepage,
        title: `${baseTitle}Login`,
        roles: ["ROLE_PUBLIC"]
    },
    LOGIN: {
        path: "/login",
        page: Login,
        title: `${baseTitle}Login`,
        roles: ["ROLE_PUBLIC"]
    },
    LOGOUT: {
        path: "/logout",
        page: Logout,
        title: `${baseTitle} Cerrar sesión`,
        roles: ["ROLE_PUBLIC"]
    },
    SIGNUP: {
        path: "/register",
        page: Register,
        title: `${baseTitle}Register`,
        roles: ["ROLE_PUBLIC"]
    },
    RECOVER_PASSWORD: {
        path: "/recover-password",
        page: ForgottenPassword,
        title: `${baseTitle}Recover password`,
        roles: ["ROLE_PUBLIC"]
    },
    RECOVER_PASSWORD_TOKEN: {
        path: "/change-password",
        params: "/:token",
        page: RecoverPassword,
        title: `${baseTitle}Recover password`,
        roles: ["ROLE_PUBLIC"]
    },
    MAINTENANCE: {
        path: "/maintenance",
        page: Maintenance,
        title: `${baseTitle}Maintenance`,
        roles: ["ROLE_PUBLIC"]
    },
    LOGIN_QR: {
        path: "/login-qr",
        page: ViewLogin,
        title: `${baseTitle}login QR`,
        roles: ["ROLE_PUBLIC"]
    },
    LOGIN_QR_IN: {
        path: "/auth/login/:token",
        page: LoginQr,
        title: `${baseTitle}login QR`,
        roles: ["ROLE_PUBLIC"]
    }

}
