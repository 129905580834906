import React, { useEffect, useRef, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import InputSelect from '../../../../../components/Admin/Inputs/InputSelect'
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import { RouteAdminDisplayEdit } from "../../../../../routes/RouteAdminDisplayEdit";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { lang } from "../../../../../utils/lang";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { apiGetScreenBlock } from "../../../../../services/Admin/screen";

import Video from "./Video";
import Image from "./Image";
import Text from "./Text";
import Expo from "./Expo";
import TxtMedia from "./TxtMedia";
import Media from "./Media";

export default function DisplayBlockEdit() {

    const { idScreen, idScreenBlock } = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [reloadPreview, setReloadPreview] = useState(null)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)
    const { t, i18n } = useTranslation()

    useEffect(() => {
        setLoadingGetInfo(true)
        if (idScreen && idScreenBlock) {
            getPageBlock()
        } else {
            setLoadingGetInfo(false)
            setState({ ...state, loading: false })
        }
    }, [state.langSelect])

    const getPageBlock = () => {

        setMsgError([])
        setMsgSuccess([])

        apiGetScreenBlock(idScreen, idScreenBlock, state?.langSelect, i18n.resolvedLanguage).then(resp => {

            let bl = resp.data.blockScreen[0];
            let blt = resp.data.blockScreenText[0];
            let bu = resp.data.baseUrl;

          if(blt==undefined)
            {
                state.blockScreenText =  {
                    id: null,
                    blockScreenId: bl.blockScreenId,
                    langSelect: state.langSelect,
                    fileVideo: [],
                    urlFileVideo: "",
                    isActiveVideo: true,
                    qrVideo: false,
                    fileImg: [],
                    urlFileImg: "",
                    isActiveImg: true,
                    qrImg: false,
                    textoLineOne: "",
                    textoLineTwo: "",
                    textoLineThree: "",
                    textoLineFour: "",
                    textoLineFive: "",
                    isActiveTexto: true,
                    qrTexto:false,
                }
            }else
            {
                state.blockScreenText = blt;
            }

            state.blockScreen = bl;
            state.baseUrl=bu;
         
            setState({

                ...state,
            })

            setLoadingGetInfo(false)

        }).catch(err => {
            setLoadingGetInfo(false)
            //setMsgError(err.response.data?.msg)
        })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage
                title="Tornar"
                urlBack={`${RouteAdminDisplayEdit.DISPLAY_EDIT.path}` + "/" + `${idScreen}`}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={state.langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setState({ ...state, langSelect: e.target.value })
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo} />

            <MsgError msg={msgError} />
            <MsgSuccess msg={msgSuccess} />

            <Grid container spacing={2} style={{ display: loadingGetInfo ? 'none' : '' }}>

                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2}>

                         {state.blockScreen?.displayBlockCode === "TYPE_VIDEO" && (
                            <Video
                                state={state}
                                setState={setState}
                                setReloadPreview={setReloadPreview}
                                setMsgSuccess={setMsgSuccess}
                                setMsgError={setMsgError}
                            />
                        )}

                       {state.blockScreen?.displayBlockCode === "TYPE_IMAGE" && (
                            <Image
                                state={state}
                                setState={setState}
                                setReloadPreview={setReloadPreview}
                                setMsgSuccess={setMsgSuccess}
                                setMsgError={setMsgError}
                            />
                        )}

                        {state.blockScreen?.displayBlockCode === "TYPE_TEXT" && (
                            <Text
                                state={state}
                                setState={setState}
                                setReloadPreview={setReloadPreview}
                                setMsgSuccess={setMsgSuccess}
                                setMsgError={setMsgError}
                            />
                        )}

                         {state.blockScreen?.displayBlockCode === "TYPE_EXPO" && (
                            <Expo
                                state={state}
                                setState={setState}
                                setReloadPreview={setReloadPreview}
                                setMsgSuccess={setMsgSuccess}
                                setMsgError={setMsgError}
                            />
                        )}

                        {state.blockScreen?.displayBlockCode === "TYPE_TXTMEDIA" && (
                            <TxtMedia
                                state={state}
                                setState={setState}
                                setReloadPreview={setReloadPreview}
                                setMsgSuccess={setMsgSuccess}
                                setMsgError={setMsgError}
                            />
                        )}

                        {state.blockScreen?.displayBlockCode === "TYPE_MEDIA" && (
                            <Media
                                state={state}
                                setState={setState}
                                setReloadPreview={setReloadPreview}
                                setMsgSuccess={setMsgSuccess}
                                setMsgError={setMsgError}
                            />
                        )} 


                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {

    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        blockScreen: props?.blockScreen || {},
        blockScreenText: props?.blockScreenText || {},

    }
}
