import React, {useEffect, useState} from "react";
import {apiGetLoginQr} from "../../../services/Auth/login";
import Grid from '@mui/material/Grid';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MsgError from "../../../components/MsgError";
import LoadingSpinner from "../../../components/LoadingSpinnerBig";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const theme = createTheme();
export default function LoginQr() {

    const {i18n} = useTranslation()
    const [urlQr, setUrlQr] = useState("")
    const [reloadQr, setReloadQr] = useState(0)

    useEffect(() => {
        getLoginQr()
    },[])

    useEffect(() => {
        const interval = setInterval(() => {
            getLoginQr()
            setReloadQr(Math.random())
        }, 900000)
        return () => clearInterval(interval)
    }, [reloadQr])

    const getLoginQr = () => {
        apiGetLoginQr(i18n.resolvedLanguage).then(resp => {
            setUrlQr(resp.data?.url)
        })
    }

    return (
        <>
            <div style={{height: "100vh", background: '#ea0c1e'}}>
                {urlQr && (<img src={urlQr} alt="qr" style={{margin: 'auto', display: 'block'}}/>)}
            </div>
        </>
    )
}
