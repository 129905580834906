import React, { useEffect, useRef, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import { apiGetPage, apiSave, apiGetListBlockFooter, apiAddBlockFooter, apiDeleteBlock } from "../../../../../services/Admin/page";
import InputSelect from '../../../../../components/Admin/Inputs/InputSelect'
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import { RouteWebBack } from "../../../../../routes/Web/RouteWebBack";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { lang } from "../../../../../utils/lang";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import BlockDnd from "./BlockDnd";
import ModalSection from "./ModalSection";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import { Link } from "react-router-dom";
import { RouteWebFront } from "../../../../../routes/Web/RouteWebFront";
import PreviewIcon from '@mui/icons-material/Preview';
import { stateToHTML } from "draft-js-export-html";
import clone from "../../../../../utils/clone";


export default function Fields() {

    let langSelectStorage = localStorage.getItem('langSelect')

    const { id } = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)
    const { t, i18n } = useTranslation()
    const [detectAddBlock, setDetectAddBlock] = useState(false)
    const [blockFooter, setBlockFooter] = useState([])
    const host = "https://www.museu-front.devplatform.tech" ;
    const [langSelect, setLangSelect] = useState(langSelectStorage ? langSelectStorage : 'ca')

    useEffect(() => {
        setLoadingGetInfo(true)
        if (id) {
            setState(initialValues({id: id}))
            getPage()
            setDetectAddBlock(true)
            getBlockFooter()
        } else {
            setLoadingGetInfo(false)
            setState({ ...state })
        }
    }, [langSelect])

    const getPage = () => {

        setMsgError([])
        setMsgSuccess([])

        apiGetPage(id, langSelect, i18n.resolvedLanguage).then(resp => {
            setState(initialValues(resp.data))
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
            setState(initialValues())

        })
    }

    const getBlockFooter = () => {

        apiGetListBlockFooter(i18n.resolvedLanguage).then(resp => {
            let footer = resp.data
            setBlockFooter(footer)
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = async () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }
        let params = clone(state)
        params.langSelect = langSelect

        apiSave(params).then(resp => {
            setLoading(false)
            
            setState({...state, id: resp.data.id })
            if (!id) {
                setMsgSuccess(resp.data.msg)
                navigate(`${RouteWebBack.WEB_BACK_EDIT.path}/${resp.data.id}`)
            } else {
                setMsgSuccess(resp.data.msg)
            }
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }


    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleAddBlockFooter = async () => {

        apiAddBlockFooter({
            pageId: id,
        }).then(resp => {
            setDetectAddBlock(true)
            setLoading(false)
            setMsgSuccess(resp.data.msg)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setDetectAddBlock(false)
            setLoading(false)
        })

    }

    const handleNavigateBlockFooter = async () => {

        navigate(`${RouteWebBack.WEB_BLOCK_FOOTEREDIT.path}/${id}`)

    }

    const previewPage = async () => {

        let url=host+"/"+langSelect

        if(state?.slugOne)
        {
            url+="/"+state?.slugOne

            if(state?.slugTwo)
            {
                url+="/"+state?.slugTwo
            }
        }

        window.open(url, '_blank');
    }


    const handleRemoveBlockFooter = async (idPageBlock) => {


        setLoading(true)
        setMsgError([])
        setMsgSuccess([])
        apiDeleteBlock({
            id: idPageBlock,
        }).then(resp => {
            setMsgSuccess(resp.data.msg)
            setLoading(false)
            setBlockFooter([])
        }).catch(err => {
            setMsgError(err.resp.msg)
            setLoading(false)
        })

    }

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage
                title="Tornar"
                urlBack={RouteWebBack.WEB_BACK_MANAGEMENT.path}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setLangSelect(e.target.value)
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo} />

            <MsgError msg={msgError} />
            <MsgSuccess msg={msgSuccess} />

            <Grid container spacing={2} style={{ display: loadingGetInfo ? 'none' : '' }}>

                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label={"Títol Intern (CA)"}
                        name="title"
                        disabled={loadingGetInfo}
                        value={state?.title}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label={"Descripció Interna (CA)"}
                        name="description"
                        disabled={loadingGetInfo}
                        value={state?.description}
                        onChange={handleChange}
                        rows={2}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label={"Títol Web"}
                        name="titleWeb"
                        disabled={loadingGetInfo}
                        value={state?.titleWeb}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                    <InputText
                        label={"Slug 1"}
                        name="slugOne"
                        disabled={loadingGetInfo}
                        value={state?.slugOne}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                    <InputText
                        label={"Slug 2"}
                        name="slugTwo"
                        disabled={loadingGetInfo}
                        value={state?.slugTwo}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label={"Títol SEO"}
                        name="titleSeo"
                        disabled={loadingGetInfo}
                        value={state?.titleSeo}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label="Descripció SEO"
                        name="descSeo"
                        disabled={loadingGetInfo}
                        value={state?.descSeo}
                        onChange={handleChange}
                        rows={3}
                    />
                </Grid>


                <Grid item xs={6} md={4}>
                    <InputCheckbox
                        label="Activat"
                        className="mb-12"
                        checked={state?.isActive}
                        onChange={(e) => {
                            state.isActive = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={6} md={4}>
                    <button type="button" onClick={(e) => previewPage()} style={{background: "transparent", border: "transparent", hover:""}}>
                        <IconButton size="small">
                            <PreviewIcon fontSize="inherit" style={{marginRight:"5px"}}/> 
                            {!state?.slugOne && (<span>{host}/{langSelect}</span>)} 
                            {(state?.slugOne && !state?.slugTwo) && (<span>{host}/{langSelect}/{state?.slugOne}</span>)} 
                            {(state?.slugOne && state?.slugTwo) && (<span>{host}/{langSelect}/{state?.slugOne}/{state?.slugTwo}</span>)}     
                        </IconButton>
                    </button>
                </Grid>


                <Grid item xs={12} md={4}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loading}
                        label={id ? 'Modificar' : 'Crear'}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <ModalSection
                        idPage={id}
                        langSelect={langSelect}
                        setDetectAddBlock={setDetectAddBlock}
                        setMsgError={setMsgError}
                        setMsgSuccess={setMsgSuccess}
                    />
                </Grid>

                <BlockDnd
                    id={id}
                    setMsgError={setMsgError}
                    setMsgSuccess={setMsgSuccess}
                    detectAddBlock={detectAddBlock}
                    setDetectAddBlock={setDetectAddBlock}
                />

                {id && blockFooter.length == 0 && (
                    <>
                        <InputButton
                            style={{ float: "right", margin: "0px 0px 10px 0px" }}
                            onClick={handleAddBlockFooter}
                            text="+ Crear block Peu de Pàgina"
                            variant="outlined"
                        />
                    </>
                )}



                {id && blockFooter.length > 0 && (
                    <>

                        <Grid item md={12}>
                            <h1>Bloc Peu de Pàgina</h1>
                        </Grid>
                        <Grid item md={12}>
                            <InputButton
                                style={{ float: "left", margin: "0px 10px 0px 0px" }}
                                onClick={() => handleNavigateBlockFooter()}
                                text="Editar"
                            />

                            {/* <InputButton
                                style={{ float: "left", margin: "0px 0px 0px 0px" }}
                                onClick={() => handleRemoveBlockFooter(blockFooter[0].pageBlockId)}
                                variant="outlined"
                                text="Eliminar"
                            /> */}
                        </Grid>

                    </>
                )}

            </Grid>
        </>
    );
}

function initialValues(props) {

    const isActive = props?.isActive === null ? true : props?.isActive

    return {
        id: props?.id || "",
        title: props?.title || "",
        description: props?.description || "",
        titleWeb: props?.titleWeb || "",
        slugOne: props?.slugOne || "",
        slugTwo: props?.slugTwo || "",
        titleSeo: props?.titleSeo || "",
        descSeo: props?.descSeo || "",
        isActive: isActive,
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.title) error.push("El títol intern és obligatori.");
    if (!schema.titleWeb) error.push("El títol web és obligatori.");
    // if (!schema.slugOne) error.push("El slug 1 és obligatori.");
    // if (!schema.slugTwo) error.push("El slug 2 és obligatori.");

    return error
}
