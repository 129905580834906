import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {apiGetLoginQrCreate} from "../../../services/Auth/login";
import useAuth from "../../../hooks/useAuth";
import {RouteUser} from "../../../routes/RouteUser";
import LoadingSpinnerBig from "../../../components/LoadingSpinnerBig";
import {removeToken} from "../../../services/Auth/token";

const theme = createTheme();
export default function LoginQr() {

    const {i18n} = useTranslation()
    const {token} = useParams()
    const {login} = useAuth()
    const navigate = useNavigate()

    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        removeToken()
        getLoginQr()
    },[])

    const getLoginQr = () => {
        setLoading(true)
        apiGetLoginQrCreate(token, i18n.resolvedLanguage).then(resp => {
            login({
                primaryUser: resp.data?.token,
                initial: true
            })
            navigate(RouteUser.SPLASH.path)
            setLoading(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoading(false)
        })
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <LoadingSpinnerBig show={loading}/>
            </Grid>
        </Grid>
    )
}
